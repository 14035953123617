import React, { useEffect, useRef, useState } from "react"
import "./App.css"

const wsHost = window.location.hostname == "localhost" ? "ws://localhost:8080" : "wss://" + window.location.host
const wsURL = wsHost + `/currentGame/ws`

function App() {
  const socket = useRef<WebSocket | null>(null)
  const [socketStatus, setSocketStatus] = useState("uninitialized")
  const [fullUpdate, setFullUpdate] = useState<any>()

  useEffect(() => {
    const ws = new WebSocket(wsURL)
    socket.current = ws

    ws.onopen = (e) => {
      setSocketStatus("open")
    }

    ws.onclose = (e) => {
      setSocketStatus("closed")
    }

    ws.onmessage = (e) => {
      let msg = JSON.parse(e.data)
      if (msg.fullUpdate) {
        setFullUpdate(msg.fullUpdate)
      }
    }

    ws.onerror = (e) => {
      setSocketStatus("error")
      console.log("WS error", e)
    }

    return () => {
      ws.close()
    }
  }, [])

  let matrix = fullUpdate && (
    <div className="matrix">
      {fullUpdate.matrix.map((row: string) => (
        <div className="row">
          {[...row].map((l) => (
            <div className="cell">{l}</div>
          ))}
        </div>
      ))}
    </div>
  )

  return (
    <div className="game">
      <pre>WS status {socketStatus}</pre>
      {fullUpdate && <h3>Hello {fullUpdate.playerName}</h3>}
      {matrix}
    </div>
  )
}

export default App
